import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "tint-plus/theme/index.css"
import "./styles/index.scss"

import CuNav from "@/components/page-nav/nav.vue"
import TintUIAnt from "tint-plus"
const app = createApp(App);
app.component('cu-nav', CuNav)
app.use(TintUIAnt)
app.use(router);
app.mount('#app')
