<template>
  <div class="cu-page-item" :class="{ 'item-bg': bgColor }">
    <div class="cu-page-item-hd">
      <div class="hd-state" :class="{ active: barState }" @click="stateClick">
        <div class="hd-state-bar"></div>
      </div>
      <div class="hd-title">
        {{ title }}
      </div>
    </div>
    <div class="cu-page-item-body" :style="bodyStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
interface Props {
  title?: string;
  bodyStyle?: any;
  bgColor?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  title: "",
  bodyStyle: {},
  bgColor: true
});

const barState = ref(false);

const stateClick = () => {
  barState.value = !barState.value;
};
</script>
<style lang="scss" scoped>
.cu-page-item {
  margin-bottom: 14px;

  &.item-bg {
    background: var(--t-color-white);
  }

  &-hd {
    padding: 0 14px;
    height: 56px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    position: relative;
  }

  .hd-state {
    width: 32px;
    height: 16px;
    border-radius: 50px;
    background: var(--t-color-primary--light-9);
    display: flex;
    align-items: center;
    padding: 0 4px;
    box-sizing: border-box;

    &-bar {
      width: 12px;
      height: 12px;
      background: var(--t-color-primary--light-6);
      border-radius: 50%;
      transition: all 0.2s linear;
    }

    &.active {
      background: var(--t-color-success--light-9);

      .hd-state-bar {
        transform: translateX(100%);
        background: var(--t-color-success--light-6);
      }
    }
  }

  .hd-title {
    margin-left: 12px;
  }

  .cu-page-item-body {
    padding: 8px 14px;
  }
}
</style>
