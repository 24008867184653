import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Layout from "@/layout/Layout.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    children: [{
      path: "",
      name: "Homes",
      component: () => import('@/views/home/Home.vue')
    }]
  },
  {
    path: '/templates',
    name: 'Templates',
    component: Layout,
    children: [{
      path: "",
      name: "Templatess",
      component: () => import('@/templates/Templates.vue')
    }]
  },
  {
    path: "/article-list",
    name: "ArticleList",
    meta: {
      title: "文章列表",
      type: "tm"
    },
    component: () => import('@/templates/article-list/ArticleList.vue')
  },
  {
    path: "/button",
    name: "Button",
    meta: {
      title: "Button按钮"
    },
    component: () => import('@/views/button/Button.vue')
  }, {
    path: "/text",
    name: "Text",
    meta: {
      title: "Text文本"
    },
    component: () => import('@/views/text/Text.vue')
  }, {
    path: "/icon",
    name: "Icon",
    meta: {
      title: "Icon图标"
    },
    component: () => import('@/views/icon/Icon.vue')
  }, {
    path: "/container",
    name: "Container",
    meta: {
      title: "Container布局容器"
    },
    component: () => import('@/views/container/Container.vue')
  }, {
    path: "/layout",
    name: "layout",
    meta: {
      title: "Layout布局"
    },
    component: () => import('@/views/layout/Layout.vue')
  }, {
    path: "/tag",
    name: "Tag",
    meta: {
      title: "Tag标签"
    },
    component: () => import('@/views/tag/Tag.vue')
  }, {
    path: "/transition",
    name: "Transition",
    meta: {
      title: "Transition动画"
    },
    component: () => import('@/views/transition/Transition.vue')
  }, {
    path: "/progress",
    name: "Progress",
    meta: {
      title: "Progress进度条"
    },
    component: () => import('@/views/progress/Progress.vue')
  }, {
    path: "/avatar",
    name: "Avatar",
    meta: {
      title: "Avatar头像"
    },
    component: () => import('@/views/avatar/Avatar.vue')
  }, {
    path: "/image",
    name: "Image",
    meta: {
      title: "Image图片"
    },
    component: () => import('@/views/image/Image.vue')
  }, {
    path: "/overlay",
    name: "Overlay",
    meta: {
      title: "Overlay遮罩层"
    },
    component: () => import('@/views/overlay/Overlay.vue')
  }, {
    path: "/popup",
    name: "Popup",
    meta: {
      title: "Popup弹出层"
    },
    component: () => import('@/views/popup/Popup.vue')
  }, {
    path: "/dialog",
    name: "Dialog",
    meta: {
      title: "Dialog弹出框"
    },
    component: () => import('@/views/dialog/Dialog.vue')
  }, {
    path: "/message",
    name: "Message",
    meta: {
      title: "Message提示"
    },
    component: () => import('@/views/message/Message.vue')
  }, {
    path: "/badge",
    name: "Badge",
    meta: {
      title: "Badge徽标"
    },
    component: () => import('@/views/badge/Badge.vue')
  }, {
    path: "/cell",
    name: "Cell",
    meta: {
      title: "Cell单元格"
    },
    component: () => import('@/views/cell/Cell.vue')
  }, {
    path: "/backup",
    name: "BackUp",
    meta: {
      title: "Backup回到顶部"
    },
    component: () => import('@/views/backup/Backup.vue')
  }, {
    path: "/carousel",
    name: "Carousel",
    meta: {
      title: "Carousel走马灯"
    },
    component: () => import('@/views/carousel/carousel.vue')
  }, {
    path: "/input",
    name: "Input",
    meta: {
      title: "Input文本框"
    },
    component: () => import('@/views/input/Input.vue')
  }, {
    path: "/textarea",
    name: "Textarea",
    meta: {
      title: "Textarea文本域"
    },
    component: () => import('@/views/textarea/Textarea.vue')
  }, {
    path: "/card",
    name: "Card",
    meta: {
      title: "Card卡片"
    },
    component: () => import('@/views/card/Card.vue')
  }, {
    path: "/search",
    name: "Search",
    meta: {
      title: "Search搜索框"
    },
    component: () => import('@/views/search/Search.vue')
  }, {
    path: "/divider",
    name: "Divider",
    meta: {
      title: "Divider分割线"
    },
    component: () => import('@/views/divider/Divider.vue')
  }, {
    path: "/tabbar",
    name: "Tabbar",
    meta: {
      title: "Tabbar底部导航"
    },
    component: () => import('@/views/tabbar/Tabbar.vue')
  }, {
    path: "/navbar",
    name: "Navbar",
    meta: {
      title: "NavBar自定义导航栏"
    },
    component: () => import('@/views/navbar/NavBar.vue')
  }, {
    path: "/scroll-view",
    name: "ScrollView",
    meta: {
      title: "ScrollView视图滑动"
    },
    component: () => import('@/views/scrollView/ScrollView.vue')
  },
  {
    path: "/tabs",
    name: "Tabs",
    meta: {
      title: "Tabs标签页"
    },
    component: () => import('@/views/tabs/Tabs.vue')
  }, {
    path: "/collapse",
    name: "Collapse",
    meta: {
      title: "Collapse折叠面板"
    },
    component: () => import('@/views/collapse/Collapse.vue')
  }, {
    path: "/action-sheet",
    name: "ActionSheet",
    meta: {
      title: "ActionSheet操作面板"
    },
    component: () => import('@/views/actionSheet/ActionSheet.vue')
  }, {
    path: "/highlight",
    name: "HighLight",
    meta: {
      title: "HighLight高亮文本"
    },
    component: () => import('@/views/highlight/HighLight.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
