<template>
    <router-view></router-view>
    <t-tabbar v-model="active">
        <t-tabbar-item :name="i.path" :to="i.path" :icon="i.icon" v-for="(i, index) in tabbar" :key="index">{{
            i.text }}</t-tabbar-item>
    </t-tabbar>
</template>

<script lang="ts" setup>
import { getCurrentInstance, ref, computed, ComponentInternalInstance } from 'vue';

const { proxy } = getCurrentInstance() as ComponentInternalInstance;

const active = computed(() => {
    return (proxy as any).$route.path
})

const tabbar = ref([{
    text: "组件",
    icon: "t-icon-shouye",
    path: "/"
}, {
    text: "模版",
    icon: "t-icon-shequ",
    path: "/templates"
}])
</script>